<template>
    <div class="order">
        <el-table ref="mainTable" :data="dataList" style="width: 100%" stripe border>
            <el-table-column prop="rptDate" label="报表日期" align="center"></el-table-column>
            <el-table-column prop="userToday" label="用户数(新增/累计)" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.userToday }}/{{ scope.row.userTotal }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="orderToday" label="新增订单数" align="center"></el-table-column>
            <el-table-column label="支付订单数(新增/累计)" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.orderTodayPay }}/{{ scope.row.orderTotalPay }}</span>
                </template>
            </el-table-column>
            <el-table-column label="支付订单金额(新增/累计)" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.orderTodayPayAmount }}/{{ scope.row.orderTotalPayAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="artistTotal" label="分类数" align="center"></el-table-column>
            <el-table-column label="商品数(上架/总计)" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.itemOnline }}/{{ scope.row.itemTotal }}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="totalCount > pageSize" class="pt" background layout="prev, pager, next" @current-change="changePage" :page-size="pageSize" :total="totalCount"> </el-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            dataList: [],
        };
    },
    mounted() {
        this.loadDataList();
    },
    methods: {
        formatter(row, column, value) {
            return value;
        },
        loadDataList: function() {
            this.$http({
                url: "/sys/operation/getReport",
                data: {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                },
            }).then((res) => {
                this.totalCount = parseInt(res.data.total);
                this.dataList = res.data.records;
            });
        },
        // 翻页
        changePage: function(value) {
            this.pageNo = value;
            this.loadDataList();
        },
    },
};
</script>
<style></style>
